export const UNQUEUE_POOLS = {
  PRODUCTION: "prod",
  BETA: "beta",
  ALPHA: "alpha",
}

export const PAYMENT_METHODS = {
  CASH: "Cash",
  DEBIT_LINX: "Debit/Linx",
  CREDIT_CARD: "Credit Card",
}

export const PICKUP_METHODS = {
  IN_STORE: "Walkin",
  CURBSIDE: "Curbside",
  DELIVERY: "Delivery",
}

export const UNQUEUE_TIME_WINDOWS = {
  DAYTIME: "11am and 3pm",
  EVENING: "3pm and 7pm",
}

export const UNQUEUE_MARKET_TIME_WINDOWS = {
  DAYTIME: "10am and 12pm",
  EVENING: "1pm and 5pm",
}

export const SHOPPER_APP_STORE_URL =
  "https://apps.apple.com/tt/app/id1518497712"
export const SHOPPER_PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.unqueue.customer"
export const BUSINESS_APP_STORE_URL =
  "https://apps.apple.com/tt/app/id1518488244"
export const BUSINESS_PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=com.unqueue.business"
